.tableContainer {
	display: flex;
	flex-direction: row;
	justify-items: stretch;

	width: 100%;
}

.table {
	flex: 1;
	width: auto;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;

	width: 100%;

	h1 {
		flex: 1;
	}
}
