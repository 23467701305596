.noTableSlide {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> * {
		margin: 1rem;
	}

	ul {
		list-style: none;
		padding: 0;
		text-align: center;
	}
}
